.App {
  font-family: 'Poppins';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.searchField input {
  border: 1px solid #0261BA;
  color: #0261BA;
}

.searchField input::placeholder {
  color: #0261BA;
}
.searchField button {
  border: 1px solid #0261BA;
  color: #0261BA;
}

.EnhancedSelectField div select {
  background-color: white;
}

.mainLeftRightPadding {
  padding-left: 6em;
  padding-right: 6em;
}

.mainTopBottomPadding {
  padding-top: 2em;
  padding-bottom: 2em;
}

.homeHeroLogo {
  text-align: center;
  margin-top: 3em;
  margin-bottom: 3em;
}

.homeHeroText {
  font-size: 2em;
  margin-right: 2.5em;
  margin-bottom: 3em;
}

.homeRegisterButton {
  padding: 10em;
}

.homeEasyToUsePanel {
  max-width: 500px;
}

.actionCard {
  max-width: 400px;
  min-width: 400px;
  padding: 1.25em;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  margin-right: 6em;
  border-radius: 4px;
}

.infoCard {
  max-width: 600px;
  min-width: 600px;
  padding: 1em;
  padding-top: 1.5em;
}
